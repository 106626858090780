<template>
  <div
    id="app_id"
    class="w-full h-full fixed"
    :class="{
      'hidden-scroll': $route.name === allowedUrls[0],
      'pointer-events-none': !isGameSupported,
    }"
    @click="startBackgroundMusic"
  >
    <template v-if="appReadyToStart">
      <section
        v-show="!isZoomInProgress"
        class="app-ui-wrapper z-10 w-full h-full pointer-events-none"
        :class="{ fixed: $route.path !== '/login' }"
      >
        <router-view
          v-if="$route.name !== RESET_PASSWORD_ROUTE_NAME"
          :display-hamburger="displayHamburger"
          @toggle-hamburger-menu="toggleHamburgerDisplayState"
          @hide-hamburger-menu="displayHamburger = false"
        />
        <router-view v-else />

        <router-view name="header" @show-hamburger-menu="toggleHamburgerDisplayState" />
        <router-view name="footer" />
        <router-view name="leftbox" />
        <router-view name="rightbox" />
        <router-view name="backButton" />
        <popups-component v-if="!isGdprPageShowed" />
      </section>
    </template>
    <map-view v-if="showMapView" />
    <teleport to="#app">
      <transition name="fade-only-in">
        <support-message v-if="!isGameSupported" />
      </transition>
    </teleport>
    <app-loading-screen />

    <!-- loading during payment on mobile -->
    <component-loading
      v-if="isPaymentLoading"
      class="absolute inset-0 z-100"
      :message="$t('premiumGroup.processingPayment')"
      :is-loading="true"
    />
    <minigame-frame v-if="appReadyToStart" :is-iframe-opened="isIframeOpened" />

    <change-device-orientation-message v-if="readyToShowPortraitModeMessage" />
  </div>
</template>

<script lang="ts">
import AppLoadingScreen from '@/components/GlobalComponents/AppLoadingScreen.vue'
import SupportMessage from '@/components/SupportMessage.vue'
import ChangeDeviceOrientationMessage from '@/components/ChangeDeviceOrientationMessage.vue'
import Popups from '@/components/Popups.vue'
import {
  ADMIN_TOKEN_EXTRACTOR_ROUTE_NAME,
  GAME_MAINTENANCE,
  GAME_UPDATE,
  LOGIN_VIEW_ROUTE_NAME,
  MAINTENANCE_ROUTE_NAME,
  MECHANIC_BENEFIT,
  MINIGAME_ID,
  PROD,
  RESET_PASSWORD_ROUTE_NAME,
  SET_LOGIN_ROUTE_NAME,
  TASK_TRACKER_LOCAL_STORAGE,
  metaPremiumPackEndpoint,
  mobileTasksEndpoint,
  pathImages,
  userProfileEndpoint,
} from '@/globalVariables'
import { windowListeners } from '@/globals/handlers/windowListeners.js'
import {
  addCookieConsent,
  addGtmScript,
  initWebAdProvider,
  metaManager,
  musicTheme,
  playSound,
  sendToFlutter,
} from '@/helpers'
import { logoutUser } from '@/helpers/misc'
import { loadTextsFromSessionStorage } from '@/plugins/getTexts'
import { routeListeners } from '@/routeDecider'
import ioService from '@/socket/SocketService'
import { useChatMessageStore } from '@/store/pinia/clubs/chatMessagesStore'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useGameStateStore } from '@/store/pinia/gameStateStore'
import { useIframeStore } from '@/store/pinia/iframeStore'
import { useMailStore } from '@/store/pinia/mailStore'
import { usePhaserStore } from '@/store/pinia/map/phaser'
import { useModeStore } from '@/store/pinia/modeStore'
import { useNewsStore } from '@/store/pinia/newsStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTextsStore } from '@/store/pinia/textStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useAccountStore } from '@/store/pinia/accountStore'
import { useFlashNewsStore } from '@/store/pinia/flashNews'
import { useBoardGamesEventStore } from '@/store/pinia/boardGamesEventStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import MinigameFrame from './MinigameFrame.vue'
import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'
import type { UserProfileEndpointInterface } from '@/interfaces/User'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import MapView from '@/views/maps/MapView.vue'
import { useTaskTrackerStore } from '@/store/pinia/career/taskTracker'

enum AllowedUrls {
  Login = LOGIN_VIEW_ROUTE_NAME,
  SetLogin = SET_LOGIN_ROUTE_NAME,
  ResetPassword = RESET_PASSWORD_ROUTE_NAME,
  AccountVerificator = 'AccountVerificator',
  AdminTokenExtractor = ADMIN_TOKEN_EXTRACTOR_ROUTE_NAME,
  Maintenance = MAINTENANCE_ROUTE_NAME,
}
enum MapViews {
  Root = '/',
  Tutorial = '/tutorial/layout',
  Career = '/career',
  Clubs = '/clubs',
  TutorialClubs = '/tutorial/clubs',
}

interface ComponentData {
  RESET_PASSWORD_ROUTE_NAME: typeof RESET_PASSWORD_ROUTE_NAME
  joinedOnSocket: boolean
  pathImages: typeof pathImages
  allowedUrls: AllowedUrls[]
  displayHamburger: boolean
  initDataLoaded: boolean
  isAlreadyPlaying: boolean
  isGameSupported: boolean
  mapViews: MapViews[]
  showMap: boolean
  orientation: string
}

export default defineComponent({
  name: 'App',
  components: {
    PopupsComponent: Popups,
    SupportMessage,
    AppLoadingScreen,
    MinigameFrame,
    MapView,
    ChangeDeviceOrientationMessage,
  },
  data(): ComponentData {
    return {
      RESET_PASSWORD_ROUTE_NAME,
      joinedOnSocket: false,
      pathImages,
      allowedUrls: [
        AllowedUrls.Login,
        AllowedUrls.SetLogin,
        AllowedUrls.ResetPassword,
        AllowedUrls.AccountVerificator,
        AllowedUrls.AdminTokenExtractor,
        AllowedUrls.Maintenance,
      ],
      displayHamburger: false,
      initDataLoaded: false,
      isAlreadyPlaying: false,
      isGameSupported: true,
      mapViews: [
        MapViews.Root,
        MapViews.Tutorial,
        MapViews.Career,
        MapViews.Clubs,
        MapViews.TutorialClubs,
      ],
      showMap: false,
      orientation: this.$screenOrientation(),
    }
  },
  computed: {
    ...mapState(useTextsStore, {
      textsLoaded: 'isTextsLoaded',
    }),
    ...mapState(useIframeStore, ['isIframeOpened']),
    ...mapState(useGameStateStore, {
      gameState: 'getGameState',
    }),
    ...mapState(useUserStore, {
      userId: 'getUserId',
      hasClub: 'getHasClub',
      isMusicEnabled: 'getMusicVolume',
      userHasToken: 'getHasToken',
      userLanguage: 'getLanguage',
      isGdprPageShowed: 'isGdprPageShowed',
    }),
    ...mapState(usePhaserStore, {
      getEmitter: 'getEmitter',
      getClubEmitter: 'getClubEmitter',
    }),
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
      getActualStage: 'getActualStage',
    }),
    ...mapState(useCoreStore, {
      isLoadingScreen: 'isLoadingScreen',
      isFirstTimeClicked: 'isFirstTimeClicked',
    }),
    ...mapState(useResponseTaskStore, {
      isUserBlocked: 'isUserBlocked',
      hasMechanic: 'hasMechanic',
      isPaymentLoading: 'isPaymentLoading',
    }),
    ...mapState(usePhaserGameIntegrationStore, ['isZoomInProgress']),
    ...mapState(useAccountStore, ['hasVerifiedAccount']),
    appReadyToStart(): boolean {
      const routeName = this.$route.name?.toString() as AllowedUrls
      return (
        ((this.initDataLoaded || this.allowedUrls.includes(routeName)) && this.textsLoaded) ||
        this.gameState === GAME_MAINTENANCE ||
        this.gameState === GAME_UPDATE
      )
    },
    showMapView(): boolean {
      if (!this.$isMobile()) return this.showMap

      const hideMapTutorialPages = [
        'chooseFirstDisciplineNarrative',
        'chooseFirstDiscipline',
        'chooseFirstDisciplineContinue',
        'chooseFirstDisciplineReturnFromMinigame',
      ]

      if (hideMapTutorialPages.includes(this.getActualStage?.name)) return false

      return this.showMap
    },
    isPortrait(): boolean {
      return this.orientation === 'portrait'
    },
    readyToShowPortraitModeMessage(): boolean {
      // If the user is not undefined = we are logged in
      // & we have portrait orientation
      return this.isPortrait && !!this.userId
    },
  },
  watch: {
    async userHasToken(value: boolean): Promise<void> {
      if (!value) return
      if (this.isUserBlocked) return

      try {
        await this.loadTutorial()
        await this.initPromise()
        this.setTrackerTasksToLocalStorage()

        if (!this.isAlreadyPlaying && this.isMusicEnabled) {
          this.musicThemePlay()
        }
        await Promise.all([
          this.loadBenefits(),
          this.loadNotifications(),
          this.loadMobileNotificationMessages(),
        ])
        if (!this.$isMobile()) initWebAdProvider(this.userId)

        this.initDataLoaded = true
      } catch (err: unknown) {
        this.logout()
      }
    },
    userId(newVal: string): void {
      if (!newVal) return
      if (this.joinedOnSocket) return

      this.joinedOnSocket = true
      ioService.setupSocketConnection()
      ioService.socket.on('new-career-notification', async (): Promise<void> => {
        const tasksResponse = await this.loadTaskTrackerQuests()
        if (tasksResponse?.tasks) {
          this.handleTasksNotifications(tasksResponse.tasks)
        }
      })
      ioService.socket.on('new-notification', (): void => {
        this.loadNotifications()
      })
      ioService.socket.on('request-rejoin', (): void => {
        ioService.socket.emit('joinNotifications', { userGameId: newVal })
      })
      ioService.socket.emit('joinNotifications', { userGameId: newVal })
    },
    hasClub(newVal: boolean): void {
      if (!newVal) return
      ioService.socket.on('newMessage', (): void => {
        if (!this.$route.name.toString().includes('Chat')) {
          this.setNewNotification(true)
        }
      })
      ioService.socket.on('notify', (): void => {
        this.setNewNotification(true)
      })
      ioService.socket.emit('join', { userGameId: this.userId })
    },
    async userLanguage(newLanguage: string): Promise<void> {
      if (!newLanguage) {
        this.initDataLoaded = true
        return
      }
      try {
        this.initDataLoaded = false
        await loadTextsFromSessionStorage()
        this.getEmitter?.emit('restartScene')
        this.getClubEmitter?.emit('restartTexts')
        this.checkIfCached()
        this.reloadTexts()
        this.initDataLoaded = true
        metaManager()
      } catch (error: unknown) {
        console.error(error)
      }
    },
    isFirstTimeClicked(value: boolean): void {
      if (value) {
        window.removeEventListener('touchend', this.setFirstClick, true)
      }
    },
  },
  async created(): Promise<void> {
    windowListeners(this)
    if (this.$isMobile()) {
      document.documentElement.id = 'html-wsm-mobile'
      document.body.classList.add('mobile')

      if (this.$isAndroid() && import.meta.env.VITE_ENV === PROD) {
        addGtmScript()
      }
    } else {
      if (import.meta.env.VITE_ENV === PROD) {
        addGtmScript()
      }
      addCookieConsent()
    }

    try {
      await this.loadLanguages(false)
      sessionStorage.removeItem(MINIGAME_ID) // v pripade refreshu stranky v minihre vymazeme id zo session

      if (this.userHasToken) {
        await this.loadTutorial()
        const locationPathname = window.location.pathname as MapViews
        if (this.mapViews.includes(locationPathname)) {
          this.setLoadingScreen(true)
        }
        if (this.gameState === GAME_MAINTENANCE || this.gameState === GAME_UPDATE) return
        await this.loadInitCalls()
      } else {
        await loadTextsFromSessionStorage()
      }
      this.checkAvifSupport()

      this.checkIfCached()
      metaManager()

      // preload ikony pre Offline popup
      const connectionLostImage = new Image()
      connectionLostImage.src = `${this.pathImages}icons/icon-connection-lost.avif`
      this.checkGroupId()

      routeListeners(this)

      // Pre render queue, lebo nemozme importovat tutorial storko tam.
      window.isTutorial = (): boolean => this.isTutorial
    } catch (err: unknown) {
      console.error(err)
    }
  },
  mounted(): void {
    window.addEventListener('orientationchange', this.handleOrientationChange)
  },
  beforeUnmount(): void {
    this.disconnectChatSocket()
    window.removeEventListener('orientationchange', this.handleOrientationChange)
  },
  methods: {
    ...mapActions(useMailStore, ['loadReceivedMail']),
    ...mapActions(useModeStore, ['loadMode']),
    ...mapActions(useCountriesStore, ['loadLanguages', 'loadCountries']),
    ...mapActions(useIframeStore, ['setIframe', 'setIframeUrl']),
    ...mapActions(useChatMessageStore, ['setNewNotification']),
    ...mapActions(usePremiumStore, [
      'generateOffers',
      'setRewardedAdLoaded',
      'setInterstitialAdLoaded',
    ]),
    ...mapActions(useUserStore, [
      'loadUserData',
      'loadSystemConfig',
      'setShowAccountConnectedPopup',
      'isRequestConsentNeeded',
    ]),
    ...mapActions(useAccountStore, ['loadConnectedAccounts']),
    ...mapActions(useTutorialStore, ['loadTutorial']),
    ...mapActions(useNewsStore, ['loadUnreadNewsCount']),
    ...mapActions(useCoreStore, ['setFirstTimeClick', 'setLoadingScreen', 'setOffline']),
    ...mapActions(useResponseTaskStore, [
      'showPaymentMessage',
      'loadMobileNotificationMessages',
      'loadNotifications',
      'setPaymentFailed',
      'loadMechanics',
      'hidePaymentLoading',
    ]),
    ...mapActions(useTaskTrackerStore, ['loadTaskTrackerQuests']),
    ...mapActions(useResponseTaskStore, {
      _loadBenefits: 'loadBenefits',
      _loadBenefitsSlots: 'loadBenefitsSlots',
      handleTasksNotifications: 'handleTasksNotifications',
    }),
    ...mapActions(useDisciplineStore, ['loadDisciplines', 'loadDisciplinesUnlockConfig']),
    ...mapActions(useEventInfoStore, ['loadEvent']),
    ...mapActions(usePhaserGameIntegrationStore, ['removeConnectAccountOsra', 'reloadTexts']),
    ...mapActions(useFlashNewsStore, ['loadFlashNews']),
    ...mapActions(useBoardGamesEventStore, { loadBoardGamesEvent: 'loadEvent' }),
    ...mapActions(useGamePassStore, {
      loadGamePassConfig: 'loadConfig',
      loadGamePassState: 'loadState',
      loadGamePassActiveEffects: 'loadEffects',
    }),
    logoutUser,
    disconnectChatSocket(): void {
      // Disconnect socket in case something is wrong
      if (!ioService.getActiveSocket) return
      ioService.socket.emit('leaveNotifications', { userGameId: this.userId })
      ioService.disconnect()
    },
    async loadInitCalls(): Promise<void> {
      await this.initPromise()
      this.setTrackerTasksToLocalStorage()
      try {
        await Promise.all([
          this.loadBenefits(),
          this.loadNotifications(),
          this.loadMobileNotificationMessages(),
          metaManager(),
        ])
        if (!this.$isMobile()) initWebAdProvider(this.userId)
      } catch (err: unknown) {
        this.logout()
      }
    },
    async initPromise(): Promise<void> {
      this.loadUnreadNewsCount()
      this.loadReceivedMail()
      await this.setLanguage()
      await Promise.all([
        this.loadUserData(),
        this.loadMode(),
        this.loadDisciplines(),
        this.loadDisciplinesUnlockConfig(),
        this.loadSystemConfig(),
        this.loadMechanics(),
        this.loadCountries(),
        this.loadLanguages(true),
        this.generateOffers(),
        this.loadConsent(),
        this.loadFlashNews(),
        this.loadEvent(),
        this.loadBoardGamesEvent(),
        this.loadConnectedAccounts(),
        this.loadGamePassConfig(),
        this.loadGamePassState(),
        this.loadGamePassActiveEffects(),
      ])
      this.showMap = true
    },
    async loadConsent(): Promise<void> {
      if (this.isTutorial) return

      const isRequestConsentNeeded = await this.isRequestConsentNeeded()
      if (!isRequestConsentNeeded) return

      this.$router.push({ name: this.$getWebView('GdprView') })
    },
    toggleHamburgerDisplayState(): void {
      !this.displayHamburger
        ? playSound('click-item-dialog-submenu')
        : playSound('click-button-dialog-close')
      this.displayHamburger = !this.displayHamburger
      if (this.displayHamburger) {
        this.loadUnreadNewsCount()
        this.loadReceivedMail()
      }
    },
    musicThemePlay(): void {
      if (musicTheme && !musicTheme.playing() && !this.$isMobile()) {
        musicTheme.play()
        this.isAlreadyPlaying = true
      }
    },
    checkIfCached(): void {
      if (!this.$t('map.attributes')) {
        this.$t('map.attributes', true)
      }
    },
    startBackgroundMusic(): void {
      if (!this.initDataLoaded) return
      if (
        !this.isAlreadyPlaying &&
        this.isMusicEnabled &&
        this.userHasToken &&
        !this.isIframeOpened
      ) {
        this.musicThemePlay()
      }
    },
    async setLanguage(): Promise<boolean> {
      if (!localStorage.getItem('language')) return false
      try {
        await this.$axios.post<{}, UserProfileEndpointInterface>(userProfileEndpoint, {
          profile_attributes: [
            {
              name: 'language',
              value: localStorage.getItem('language'),
            },
          ],
        })
        return true
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadBenefits(): Promise<void> {
      if (!this.hasMechanic(MECHANIC_BENEFIT)) {
        this.initDataLoaded = true
        return
      }
      try {
        await Promise.all([this._loadBenefits(), this._loadBenefitsSlots()])
        this.initDataLoaded = true
      } catch (error: unknown) {
        this.logout()
      }
    },
    logout(): void {
      if (this.$isMobile()) {
        sendToFlutter(
          JSON.stringify({
            event: 'disconnect',
          }),
        )
      }
      this.logoutUser()
    },
    async accountConnected(): Promise<void> {
      // this method is called from mobile when
      // FB, Google, Apple - are connected
      // Email - not verified
      this.loadUserData()
      try {
        this.$axios.get<{}, true>(mobileTasksEndpoint)
      } catch (error: unknown) {
        console.error(error)
      }
      this.setShowAccountConnectedPopup(true)
      await Promise.all([this.loadConnectedAccounts(true), this.loadMobileNotificationMessages()])
      if (this.hasVerifiedAccount) this.removeConnectAccountOsra()
    },
    async reloadConnections(): Promise<void> {
      // this method is called from mobile when
      // email is verified
      await this.loadConnectedAccounts(true)
      if (this.hasVerifiedAccount) this.removeConnectAccountOsra()
    },
    checkLoadingDone(): void {
      if (this.isLoadingScreen === false) {
        sendToFlutter('{\r\n  "event": "loadingDone"\r\n}')
      }
    },
    async showPaymentSessionMessage(pack: string, result: string): Promise<void> {
      const bundleId = pack
      const failed = result === 'false'
      if (failed) {
        this.setPaymentFailed(true)
        this.hidePaymentLoading()
        return
      }
      // get gem amount from endpoint
      const response = await this.$axios.get<{}, PremiumPackApiResponse>(
        metaPremiumPackEndpoint + bundleId,
      )
      // commit to store. this should open the payment session message
      this.showPaymentMessage({
        pack: bundleId,
        rewards: response.rewards,
        failed: failed,
        af_revenue: response.firebaseData.af_revenue,
        af_currency: response.firebaseData.af_currency,
        af_quantity: response.firebaseData.af_quantity,
        af_store_id: response.firebaseData.af_store_id,
        gtm_currency: response.firebaseData.gtm_currency,
        gtm_revenue: response.firebaseData.gtm_revenue,
      })
      // hide loading indicator for payments
      this.hidePaymentLoading()
    },
    addLoginFinished(_type: string, isConnected: boolean): void {
      if (!isConnected) return
      this.loadUserData()
    },
    checkGroupId(): void {
      const idGroupLS = JSON.parse(localStorage.getItem('id_group'))
      if (!idGroupLS) return
      const actualDateInMs = new Date().getTime()
      if (actualDateInMs >= idGroupLS.expiry) localStorage.removeItem('id_group')
    },
    stopIframe(): void {
      this.setIframe(false)
      this.setIframeUrl('')
    },
    setTrackerTasksToLocalStorage(): void {
      const storage = localStorage.getItem(TASK_TRACKER_LOCAL_STORAGE)
      if (storage !== null) return
      localStorage.setItem(TASK_TRACKER_LOCAL_STORAGE, JSON.stringify([false, false, false, false]))
    },
    setFirstClick(): void {
      localStorage.setItem('firstClick', 'true')
      this.setFirstTimeClick(true)
      sendToFlutter('{\r\n  "event": "firstTimeClicked"\r\n}')
    },
    checkAvifSupport(): void {
      const img = new Image()

      // 1×1px avif
      img.src =
        'data:image/avif;base64,AAAAHGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZgAAAZhtZXRhAAAAAAAAACFoZGxyAAAAAAAAAABwaWN0AAAAAAAAAAAAAAAAAAAAAA5waXRtAAAAAAABAAAANGlsb2MAAAAAREAAAgACAAAAAAG8AAEAAAAAAAAAFAABAAAAAAHQAAEAAAAAAAAAGgAAADhpaW5mAAAAAAACAAAAFWluZmUCAAAAAAEAAGF2MDEAAAAAFWluZmUCAAAAAAIAAGF2MDEAAAAA12lwcnAAAACxaXBjbwAAABNjb2xybmNseAACAAIABoAAAAAMYXYxQ4EAHAAAAAAUaXNwZQAAAAAAAAABAAAAAQAAAA5waXhpAAAAAAEIAAAAOGF1eEMAAAAAdXJuOm1wZWc6bXBlZ0I6Y2ljcDpzeXN0ZW1zOmF1eGlsaWFyeTphbHBoYQAAAAAMYXYxQ4EADAAAAAAUaXNwZQAAAAAAAAABAAAAAQAAABBwaXhpAAAAAAMICAgAAAAeaXBtYQAAAAAAAAACAAEEgYYHiAACBIIDhIUAAAAaaXJlZgAAAAAAAAAOYXV4bAACAAEAAQAAADZtZGF0EgAKBBgABhUyChgAKKEAAiEbo2ASAAoIGAAGCBAQNCAyDBgACiiihAAAsBNL2A=='

      img.onerror = (): void => {
        // browser does not support avif
        this.isGameSupported = false
      }
    },
    failedLoadingRewardedAds(): void {
      this.setRewardedAdLoaded(false)
    },
    loadedRewardedAds(): void {
      this.setRewardedAdLoaded(true)
    },
    failedLoadingInterstitialAds(): void {
      this.setInterstitialAdLoaded(false)
    },
    loadedInterstitialAds(): void {
      this.setInterstitialAdLoaded(true)
    },
    setOfflineState(value): void {
      this.setOffline(value)
    },
    handleOrientationChange(): void {
      setTimeout((): void => {
        this.orientation = this.$screenOrientation()
      }, 300)
    },
  },
})
</script>

<style lang="scss">
#app {
  text-align: center;
  color: #2c3e50;
  padding: env(safe-area-inset);

  @if $isWsm {
    font-family:
      Roboto Condensed,
      Helvetica,
      Arial,
      sans-serif;
  }

  @if $isSsm {
    font-family: 'TT Lakes Condensed', Helvetica, Arial, sans-serif;
  }

  &_id {
    z-index: 1;
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  #app {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    --safe-area-inset-left: env(safe-area-inset-left);
    --safe-area-inset-right: env(safe-area-inset-right);
  }
}

.app-page-wrapper {
  background: url($path-images + 'backgrounds/app-wrapper-bg.avif') center bottom no-repeat;
  background-size: cover;
  top: 0;
}

.hidden-scroll {
  overflow-y: hidden;
}

#game-placeholder {
  position: absolute;
  z-index: -1;
}

.app-ui-wrapper {
  & > * {
    pointer-events: all;
  }
}
</style>
